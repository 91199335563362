import { FlowType } from './middlewareHelpers';

// Define the page flows and their respective configurations
// Remember to update the middleware config for any new page flows
export const pageFlows: FlowType[] = [
  {
    cookieName: 'bucket-reed',
    buckets: [{ name: 'a', weight: 100 }],
    baseUrl: '/partnerships/reed',
    locale: 'uk',
  },
  {
    cookieName: 'bucket-ndpp-full-delivery',
    buckets: [{ name: 'a', weight: 100 }],
    baseUrl: '/partnerships/ndpp-full-delivery',
    locale: 'uk',
  },
  {
    cookieName: 'bucket-ndpp-thrive-tribe',
    buckets: [{ name: 'control', weight: 100 }],
    baseUrl: '/partnerships/ndpp-thrive-tribe',
    locale: 'uk',
  },
  {
    cookieName: 'bucket-ndpp-hypertension-reed',
    buckets: [{ name: 'control', weight: 100 }],
    baseUrl: '/partnerships/ndpp-hypertension-reed',
    locale: 'uk',
  },
  {
    cookieName: 'bucket-ndpp-hypertension-thrive-tribe',
    buckets: [{ name: 'control', weight: 100 }],
    baseUrl: '/partnerships/ndpp-hypertension-thrive-tribe',
    locale: 'uk',
  },
  {
    cookieName: 'bucket-wmsl3',
    buckets: [{ name: 'a', weight: 100 }],
    baseUrl: '/partnerships/wmsl3',
    locale: 'uk',
  },
  {
    cookieName: 'bucket-wmsl1',
    buckets: [{ name: 'control', weight: 100 }],
    baseUrl: '/partnerships/wmsl1',
    locale: 'uk',
  },
  {
    cookieName: 'bucket-type-2',
    buckets: [{ name: 'control', weight: 100 }],
    baseUrl: '/partnerships/type-2',
    locale: 'uk',
  },
  {
    cookieName: 'bucket-restart',
    buckets: [{ name: 'control', weight: 100 }],
    baseUrl: '/restart',
    locale: 'uk',
  },
  // Local weight management tech contracts
  {
    cookieName: 'bucket-lwmt',
    buckets: [{ name: 'control', weight: 100 }],
    baseUrl: '/partnerships/lwmt',
    locale: 'uk',
  },
  // ABL local weight management tech contract
  {
    cookieName: 'bucket-abl-lwmt',
    buckets: [{ name: 'control', weight: 100 }],
    baseUrl: '/partnerships/abl-lwmt',
    locale: 'uk',
  },
  // Local weight management no-tech contracts
  {
    cookieName: 'bucket-lwmnt',
    buckets: [{ name: 'control', weight: 100 }],
    baseUrl: '/partnerships/lwmnt',
    locale: 'uk',
  },
  // Local weight management digital contracts
  {
    cookieName: 'bucket-lwmd',
    buckets: [{ name: 'control', weight: 100 }],
    baseUrl: '/partnerships/lwmd',
    locale: 'uk',
  },
  // Tier 3 weight management Scottish Highlands
  {
    cookieName: 'bucket-t3wmsh',
    buckets: [{ name: 'control', weight: 100 }],
    baseUrl: '/partnerships/t3wmsh',
    locale: 'uk',
  },
  // Tier 3 weight management (active weight loss)
  {
    cookieName: 'bucket-t3wmawl',
    buckets: [{ name: 'control', weight: 100 }],
    baseUrl: '/partnerships/t3wmawl',
    locale: 'uk',
  },
  {
    cookieName: 'bucket-ndpp-scotland',
    buckets: [{ name: 'control', weight: 100 }],
    baseUrl: '/partnerships/ndpp-scotland',
    locale: 'uk',
  },
  {
    cookieName: 'bucket-awm',
    buckets: [{ name: 'control', weight: 100 }],
    baseUrl: '/partnerships/awm',
    locale: 'uk',
  },
  // Vitality referral flows
  {
    cookieName: 'bucket-vitality',
    buckets: [{ name: 'quiz', weight: 100 }],
    baseUrl: '/partnerships/vitality',
    locale: 'uk',
  },
  {
    cookieName: 'bucket-employee-vitality',
    buckets: [{ name: 'control', weight: 100 }],
    baseUrl: '/partnerships/employee-vitality',
    locale: 'uk',
  },
  {
    cookieName: 'bucket-sn-vitality',
    buckets: [{ name: 'quiz', weight: 100 }],
    baseUrl: '/partnerships/sn-vitality',
    locale: 'uk',
  },
  // Consumer Quiz
  {
    cookieName: 'bucket-consumer-quiz',
    buckets: [{ name: 'b', weight: 100 }],
    baseUrl: '/get-plan',
    locale: 'uk',
  },
  {
    cookieName: 'bucket-consumer-quiz-us',
    buckets: [{ name: 'us-control', weight: 100 }],
    baseUrl: '/get-plan',
    locale: 'us',
  },
  // Checkout
  {
    cookieName: 'bucket-checkout',
    buckets: [{ name: 'b', weight: 100 }],
    baseUrl: '/checkout',
    locale: 'uk',
  },
];
