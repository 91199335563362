import { calculateAge } from './quizUtils';
import { QuizStateType } from '../components/Quiz/state/state';
import { VitalityQuizStateType } from '../components/VitalityQuiz/state/state';

/**
 * Converts boolean values to 'Yes' or 'No' strings for tracking
 */
export const formatBoolean = (value: boolean | undefined | null): string => {
  if (value === undefined || value === null) {
    return 'No';
  }
  return value ? 'Yes' : 'No';
};

/**
 * Formats the age in years from a date of birth string e.g. '1990-03-20' -> 34
 */
export const formatAge = (dateOfBirth: Date | undefined): number =>
  calculateAge(
    dateOfBirth
      ? new Date(dateOfBirth)?.toISOString()
      : new Date().toISOString(),
  );

/**
 * Maps quiz answers to their tracking-friendly format
 */
export const formatAnswersForTracking = (
  answers: QuizStateType | VitalityQuizStateType,
): Record<string, unknown> => {
  const {
    healthConditionsText,
    hasWeightLossGoal,
    hasOtherHealthConditions,
    hasDiabetesDoctorsAppointment,
    allergyDetails,
    pregnancy,
    secondaryEthnicity,
    howDoYouLikeYourGoals,
    ...remainingAnswers
  } = answers;

  return {
    ...remainingAnswers,
    healthConditionsOpenText: healthConditionsText,
    wantsWeightLossGoal: formatBoolean(hasWeightLossGoal),
    HasWeightGoalInMind: formatBoolean(hasWeightLossGoal),
    hasHealthCondition: formatBoolean(hasOtherHealthConditions),
    hasOtherHealthConditions: formatBoolean(hasOtherHealthConditions),
    hasDiabetesDoctorsAppt: hasDiabetesDoctorsAppointment,
    allergies: allergyDetails,
    age: formatAge(answers.dateOfBirth),
    pregnancyStatus: pregnancy,
    ethnicity: secondaryEthnicity,
    goals: howDoYouLikeYourGoals || null,
  };
};
