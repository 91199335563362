import { getBMI } from '../../../src/utils/numberUtils';
import {
  EligibilityCriteria,
  EligibilityResult,
  qualifiesForWeightLossProgramme,
  reduceEligibilityCriteria,
} from '../../components/Quiz/QuizUtils';
// Don't use an aliased import here, or Cypress breaks
import { getGLP1BaseCriteria } from './baseEligibility';
import {
  getWeightRelatedComorbidities,
  meetsGLP1BMICriteriaWithComorbidity,
} from './comorbidityEligibility';
import { qualifiesForGLP1Programme } from './extendedEligibility';
import { getLocalStorage } from '../../components/NhsSignup/helpers';
import {
  GLP1QuizEligiblityAnswers,
  QuizStateType,
  initialState,
} from '../../components/Quiz/state/state';
import { MainContextType } from '../../components/context/MainContextType';

/**
 * Fetches the user's GLP1 eligibility answers from the MainContext
 * @param user The user object from the MainContext
 * @returns
 */
export const getGLP1EligibilityAnswersFromUserState = (
  user: MainContextType['user'],
): GLP1QuizEligiblityAnswers | undefined => {
  if (!user) {
    return undefined;
  }

  const {
    birthday,
    heightCm,
    weightKg,
    gender,
    pregnancyStatus,
    healthConditions = [],
    personalHabits = [],
    healthConditionsComorbiditiesDetails = [],
    diabetesStatus,
    diabetesManagement = [],
    hasDiabetesDoctorsAppt,
    diabetesComplications = [],
    diabetesMedicationPrescriber = '',
    type2DiabetesMedication = [],
    openToMedication,
  } = user;

  // If any of the required fields are missing, return undefined
  if (!birthday || !heightCm || !weightKg || !gender) {
    return undefined;
  }

  return {
    dateOfBirth: birthday,
    weightKg,
    heightCm,
    gender,
    pregnancy: pregnancyStatus,
    healthConditions,
    personalHabits,
    // Health Conditions Comorbidities Details are stored as an object in the user state
    // We need to convert it to an array of keys to match the quiz state
    healthConditionsComorbidities: Object.keys(
      healthConditionsComorbiditiesDetails,
    ) as keyof typeof healthConditionsComorbiditiesDetails,
    diabetesStatus,
    diabetesManagement,
    hasDiabetesDoctorsAppointment: hasDiabetesDoctorsAppt,
    diabetesComplications,
    diabetesMedicationPrescriber,
    type2DiabetesMedications: type2DiabetesMedication,
    openToMedication,
  };
};

/**
 * Get a user's eligibility for the GLP-1 programme based on their quiz answers
 * stored in the 'cached-quiz' local storage.
 *
 * This function checks eligibility based on the quiz flow.
 * It retrieves the user's answers from the local storage under the 'cached-quiz' key
 * and checks if the user is eligible for the GLP-1 programme and if they are open to medication.
 *
 * If the user is eligible based on the quiz flow answers, the function returns true.
 *
 * @param {string} [locale] - Locale for the user.
 * @return {boolean} Whether the user is eligible for the GLP-1 programme.
 */
export const getGLP1EligibilityFromLocalStorage = (
  locale?: string,
): boolean => {
  const quizFlowAnswers = getLocalStorage('cached-quiz', initialState);
  const glp1EligibilityQuizFlow = qualifiesForGLP1Programme(
    quizFlowAnswers,
    locale,
  );

  const isEligibleBasedOnQuizFlow =
    glp1EligibilityQuizFlow.isEligible &&
    quizFlowAnswers.openToMedication !== 'noMedication';

  return isEligibleBasedOnQuizFlow;
};

/**
 * If provided with answers, this function will determine if the user is eligible for the GLP-1 programme.
 * Otherwise, it will determine if the user is eligible based on the answers stored in the 'cached-quiz' local storage.
 * @returns {boolean} Whether the user is eligible for the GLP-1 programme.
 */
export const getGLP1Eligibility = ({
  answers,
  locale,
}: {
  answers?: GLP1QuizEligiblityAnswers;
  locale?: string;
}): boolean => {
  if (answers) {
    return (
      qualifiesForGLP1Programme(answers, locale).isEligible &&
      answers?.openToMedication !== 'noMedication'
    );
  }

  return getGLP1EligibilityFromLocalStorage(locale);
};

/**
 * Get a user's quiz answers from local storage. Useful when quiz answers need
 * to be accessed outside of the QuizProvider (i.e. at checkout).
 *
 * If the user does not have any quiz answers in local storage, the function
 * returns the initial quiz state.
 *
 * @returns {QuizStateType} User's quiz answers from local storage.
 */
export const getQuizAnswersFromLocalStorage = (): QuizStateType =>
  getLocalStorage('cached-quiz', initialState);

/**
 * Extracts the answer to the openToMedication question from the quiz flow
 *
 * The answer to this question specifically is used on the pricing screen
 *
 * @returns {string} key representing the answer to the openToMedication quiz question
 */
export const getIsOpenToMedicationFromQuizAnswers = (): string | undefined => {
  const quizFlowAnswers = getQuizAnswersFromLocalStorage();
  return quizFlowAnswers.openToMedication;
};

/**
 * Determines whether a user should be shown the Vitality programme question, taking all their screening
 * answers into account. This will be shown if they're eligible for GLP-1 but don't want to inject medication.
 * @param {Pick<QuizStateType>} answers - The user's answers to the quiz questions.
 * @returns {boolean} Whether the user qualifies for the Vitality programme.
 */
const shouldBeShownVitalityQuestion = (
  answers: Pick<
    QuizStateType,
    'weightKg' | 'heightCm' | 'openToMedication' | 'healthConditions'
  >,
): EligibilityResult => {
  const { weightKg, heightCm, openToMedication, healthConditions } = answers;

  const bmi = getBMI(weightKg || 0, heightCm || 0);

  const hasDiabetes =
    healthConditions.includes('Type 2 diabetes') ||
    healthConditions.includes('Type 1 diabetes');

  // An array of criteria to determine whether the user qualifies for Vitality
  const criteria: EligibilityCriteria[] = [
    {
      key: 'bmi',
      value: bmi,
      isValid: (!!bmi && bmi >= 30) || (!!bmi && bmi >= 25 && hasDiabetes),
    },
    {
      key: 'openToMedication',
      value: openToMedication,
      isValid: openToMedication === 'noMedication',
    },
  ];

  return reduceEligibilityCriteria(criteria);
};

/**
 * Determines whether a user should see the Vitality member question.
 * Should only show if not eligible for GLP1, but with BMI >30.
 *
 * @param {Partial<QuizStateType>} answers - The user's answers to the quiz questions.
 * @returns {boolean} Whether the user qualifies for GLP1 quiz screens.
 */
export const shouldShowVitalityQuestionGLP1 = (
  answers: Partial<QuizStateType>,
): boolean => {
  const safeAnswers: Pick<
    QuizStateType,
    'weightKg' | 'heightCm' | 'openToMedication' | 'healthConditions'
  > = {
    weightKg: answers.weightKg || 0,
    heightCm: answers.heightCm || 0,
    openToMedication: answers.openToMedication || '',
    healthConditions: answers.healthConditions || [],
  };

  return (
    qualifiesForWeightLossProgramme(answers).isEligible &&
    shouldBeShownVitalityQuestion(safeAnswers).isEligible
  );
};

/**
 * Determines whether a user has answered having a specified health comorbidity. If so, we should
 * show the health comorbidity details question.
 *
 * @param {Pick<QuizStateType>} answers - The user's answers to the quiz questions.
 * @returns {boolean} Whether the user has specified a health comorbidity.
 */
export const shouldNavigateToHealthConditionsComorbiditiesQuestion = (
  answers: Pick<
    QuizStateType,
    | 'weightKg'
    | 'heightCm'
    | 'healthConditions'
    | 'pregnancy'
    | 'gender'
    | 'diabetesStatus'
  >,
  locale?: string,
): boolean => {
  const { weightKg, heightCm } = answers;

  const bmi = getBMI(weightKg || 0, heightCm || 0);

  const glp1CriteriaWithoutBMI = getGLP1BaseCriteria(answers, locale).filter(
    criterion => criterion.key !== 'bmi',
  );

  // Criteria used to determine if a user should see the healthConditionsComorbidities question
  // which may enable them to be eligible for the GLP1 programme
  const criteria: EligibilityCriteria[] = [
    ...glp1CriteriaWithoutBMI,
    {
      key: 'bmi',
      value: bmi,
      isValid: meetsGLP1BMICriteriaWithComorbidity(bmi),
    },
  ];

  return reduceEligibilityCriteria(criteria).isEligible;
};

export const shouldNavigateToHealthConditionsComorbiditiesDetailsQuestion = (
  answers: Partial<QuizStateType>,
): boolean => {
  const safeAnswers: Pick<
    QuizStateType,
    'healthConditions' | 'healthConditionsComorbidities'
  > = {
    healthConditions: answers.healthConditions || [],
    healthConditionsComorbidities: answers.healthConditionsComorbidities || [],
  };

  return getWeightRelatedComorbidities(safeAnswers).length > 0;
};
